



























import {Vue, Component, Prop} from 'vue-property-decorator';

import Detail from '@/shared/resources/components/details/Detail.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import Order from '@/app/lib/order/Order';
import { PaymentsEnum } from '@/modules/payments/enums/PaymentsEnum';
import AccountNumberAlert from '@/app/components/AccountNumberAlert.vue';

@Component({
  components: {
    AccountNumberAlert,
    Detail,
  },
})
export default class CartSummaryDetails extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;
  @Prop() private deliveryPrice!: string;

  /**
   * Getters
   */
  private get deliveryValue(): string {
    return `${this.$t('deliveries.' + this.order.delivery + '.name')} (${this.deliveryPrice})`;
  }

  /**
   * Display getters
   */
  private get displayPaymentDetail(): boolean {
    return !!this.order.payment;
  }

  private get displayCommentsDetail(): boolean {
    return !!this.order.comments;
  }

  private get displayAccountNumberAlert(): boolean {
    return this.order.payment === PaymentsEnum.BANKWIRE;
  }
}
